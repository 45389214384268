import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typography } from 'tfc-components';

import Button from 'components/atoms/Button';
import Navigate from 'components/organisms/Navigate';
import { getInfoShopMatchByCodeService, postCheckInByShopCodeService } from 'services/shops';
import { PARAM_SEARCH, ROUTES } from 'utils/constants';
import { compareDateTimeNow, formatDate } from 'utils/functions';

const InfoCustomer: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const NAME_CUSTOMER = searchParams.get(PARAM_SEARCH.NAME_CUSTOMER);
  const ADDRESS_CUSTOMER = searchParams.get(PARAM_SEARCH.ADDRESS_CUSTOMER);
  const CODE_CUSTOMER = searchParams.get(PARAM_SEARCH.CODE_CUSTOMER);
  const [isFirstTimeCheckin, setIsFirstTimeCheckin] = React.useState(true);
  const [checkinStatus, setCheckinStatus] = React.useState(false);

  const { data: customer, isLoading: isCustomerLoading } = useQuery({
    queryKey: ['getInfoShopMatchByCodeService', CODE_CUSTOMER],
    queryFn: () => getInfoShopMatchByCodeService(CODE_CUSTOMER || ''),
    enabled: !!CODE_CUSTOMER,
    onError: () => {
      navigate(ROUTES.MANUAL_INPUT);
    }
  });

  const { mutate: checkinMutate, isLoading: isCheckinLoading } = useMutation({
    mutationKey: ['check-in-by-shop-code', CODE_CUSTOMER],
    mutationFn: postCheckInByShopCodeService,
    onSuccess: () => {
      setCheckinStatus(true);
    },
    onError: () => {
      setCheckinStatus(false);
      toast.error('Checkin không thành công');
    },
  });

  useEffect(() => {
    const checkInState = compareDateTimeNow(
      customer?.event_time?.start_conference?.split(' ')[0] || ''
    );

    setIsFirstTimeCheckin(!customer?.checked_in_at);
    setCheckinStatus(checkInState);
  }, [customer?.checked_in_at, customer?.event_time?.start_conference]);

  const renderHeadingContent = () => {
    if (!checkinStatus) {
      return <>CHECKIN KHÔNG THÀNH CÔNG</>;
    }
    if (isFirstTimeCheckin) {
      return (
        <>
          CHÀO MỪNG QUÝ KHÁCH HÀNG THAM DỰ
          <br />
          HỘI NGHỊ GẮN BÓ KHÁCH HÀNG
        </>
      );
    }
    return <>KHÁCH HÀNG ĐÃ CHECKIN</>;
  };

  return (
    <div className="p-infoCustomer">
      <Navigate />
      <Container>
        {isCustomerLoading ? (
          <div>Đang tải...</div>
        ) : (
          <div className="p-infoCustomer_container">
            <Typography.Heading
              type="h5"
              fontweight="600"
              extendClasses={`p-infoCustomer_heading ${(!checkinStatus || !isFirstTimeCheckin) && 'text-danger'}`}
            >
              {renderHeadingContent()}
            </Typography.Heading>
            <div className="p-infoCustomer_info">
              <div className="p-infoCustomer_item">
                <Typography.Text type="span" fontweight="700">
                  Tên:
                </Typography.Text>
                <Typography.Text type="span">{NAME_CUSTOMER}</Typography.Text>
              </div>
              <div className="p-infoCustomer_item">
                <Typography.Text type="span" fontweight="700">
                  Địa chỉ:
                </Typography.Text>
                <Typography.Text type="span">{ADDRESS_CUSTOMER}</Typography.Text>
              </div>
              <div className="p-infoCustomer_item">
                <Typography.Text type="span" fontweight="700">
                  Mã Khách hàng:
                </Typography.Text>
                <Typography.Text type="span">{CODE_CUSTOMER}</Typography.Text>
              </div>
              <div className="p-infoCustomer_item">
                <Typography.Text type="span" fontweight="700">
                  Ngày tham dự chương trình:
                </Typography.Text>
                <Typography.Text type="span">
                  {formatDate(customer?.event_time?.start_conference ?? '')}
                </Typography.Text>
              </div>
            </div>
            {isFirstTimeCheckin && checkinStatus ? (
              <Button
                loading={isCheckinLoading}
                variant={['h50']}
                handleClick={() => {
                  checkinMutate({
                    is_finished: checkinStatus,
                    shop_code: CODE_CUSTOMER || '',
                  });
                  navigate(ROUTES.CHECKIN_END);
                }}
              >
                Hoàn Tất
              </Button>
            ) : (
              <>
                <div className="p-infoCustomer_btnGroup">
                  <Button
                    variant={['h50']}
                    handleClick={() => {
                      navigate(ROUTES.SCAN);
                    }}
                    className="p-infoCustomer_qr"
                  >
                    Scan QR
                  </Button>
                  <Button
                    variant={['h50']}
                    handleClick={() => {
                      navigate(ROUTES.MANUAL_INPUT);
                    }}
                  >
                    Nhập thông tin
                  </Button>
                </div>
                <Button
                  variant={['h50']}
                  handleClick={() => {
                    navigate(ROUTES.HOME);
                  }}
                  className="p-infoCustomer_qr"
                >
                  Về trang chủ
                </Button>
              </>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};
export default InfoCustomer;
