import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CheckinResult from 'pages/CheckinResult';
import Home from 'pages/Home';
import InfoCustomer from 'pages/InfoCustomer';
import ManualInput from 'pages/ManualInput';
import Scan from 'pages/Scan';
import { store } from 'store';
import { ROUTES } from 'utils/constants';

const App: React.FC = () => (
  <Suspense>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path={ROUTES.CHECKIN_END} element={<CheckinResult />} />
      <Route path={ROUTES.SCAN} element={<Scan />} />
      <Route path={ROUTES.INFORMATION_CUSTOMER} element={<InfoCustomer />} />
      <Route path={ROUTES.MANUAL_INPUT} element={<ManualInput />} />
    </Routes>
  </Suspense>
);

const AppProvider: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <BrowserRouter>
            <App />
            <ToastContainer autoClose={3500} />
          </BrowserRouter>
        </HelmetProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppProvider;
