export const LOCAL_STORAGE = {
  LANGUAGE: 'CLP_CHECKIN_Language',
  ACCESS_TOKEN: 'CLP_CHECKIN_ACCESS_TOKEN',
  REFRESH_TOKEN: 'CLP_CHECKIN_REFRESH_TOKEN',
};

export const PARAM_SEARCH = {
  NAME_CUSTOMER: 'name_customer',
  ADDRESS_CUSTOMER: 'address_customer',
  CODE_CUSTOMER: 'code_customer',
  CARD_CODE_CUSTOMER: 'card_code_customer',
};

export const ROUTES = {
  HOME: '/',
  INFORMATION_CUSTOMER: '/information-customer',
  SCAN: '/scan',
  CHECKIN_END: '/checkin-end',
  MANUAL_INPUT: '/input_info'
};

export const EMPTY = '';
