import React from 'react';

import loadingImg from 'assets/images/loading.gif';
import Link from 'components/atoms/Link';
import mapModifiers from 'utils/functions';

type Variant = 'secondary' | 'warning' | 'h50' | 'blue' | 'uppercase';
type Sizes = 'sm';

interface ButtonProps {
  size?: Sizes;
  variant?: Variant | Variant[];
  color?: ColorStyle;
  type?: 'button' | 'submit';
  disabled?: boolean;
  loading?: boolean;
  href?: string;
  target?: string;
  className?: string;
  useLink?: boolean;
  handleClick?: () => void;
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  size,
  variant,
  color,
  type,
  disabled,
  loading,
  handleClick,
  className,
  href,
  target,
  useLink,
  children,
}) => {
  const childButton = () => <span>{children}</span>;
  if (useLink) {
    return (
      <Link
        className={mapModifiers('a-button', size, variant, color)}
        href={href || ''}
        target={target}
      >
        <span>{children}</span>
      </Link>
    );
  }
  return (
    /* eslint-disable react/button-has-type */
    <button
      className={mapModifiers(
        'a-button',
        size,
        variant,
        color,
        disabled && 'disabled',
        loading && 'loading',
        className
      )}
      onClick={handleClick}
      type={type}
      disabled={disabled}
    >
      {loading ? (
        <span className="a-button_loading">
          <img src={loadingImg} alt="loading" />
        </span>
      ) : (
        childButton()
      )}
    </button>
  );
};

Button.defaultProps = {
  variant: undefined,
  size: undefined,
  color: undefined,
  type: 'button',
  disabled: false,
  loading: false,
  handleClick: undefined,
  href: undefined,
  target: undefined,
  useLink: undefined,
  className: undefined,
};

export default Button;
