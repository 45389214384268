import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Container from 'components/organisms/Container';
import Navigate from 'components/organisms/Navigate';
import { getInfoShopMatchByCodeService } from 'services/shops';
import { ROUTES } from 'utils/constants';

const ManualInput = () => {
  const navigate = useNavigate();
  const [inputCode, setInputCode] = useState('');

  const { mutate: getInfoShopMatchByCodeMuatate, isLoading } = useMutation(
    'getInfoShopMatchByCodeService',
    (code: string) => getInfoShopMatchByCodeService(code),
    {
      onSuccess(data) {
        const params = {
          name_customer: data.name || '',
          address_customer: data.address || '',
          code_customer: data.code || '',
        };
        navigate(`${ROUTES.INFORMATION_CUSTOMER}?${new URLSearchParams(params).toString()}`);
      },
      onError() {
        toast.error('Thông tin không hợp lệ! Vui lòng thử lại');
      },
    }
  );

  return (
    <div className="p-manualInput">
      <Navigate />
      <Container>
        <div className="p-manualInput_container">
          <Text modifiers={['center', 'pigment', '700']}>NHẬP MÃ KHÁCH HÀNG</Text>
          <Input
            id="code"
            name="code"
            className="p-manualInput_input"
            value={inputCode}
            onChange={(e) => setInputCode(e.target.value)}
          />

          <Button loading={isLoading} handleClick={() => getInfoShopMatchByCodeMuatate(inputCode)}>
            XÁC NHẬN
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default ManualInput;
