import React from 'react';

import mapModifiers from 'utils/functions';

export type Sizes =
  | 'sm'
  | 'md'
  | 'md-lg'
  | 'md-sm'
  | 'lg'
  | '8x10'
  | '12x16'
  | '14x19'
  | '22x26'
  | '16x22'
  | '19x26'
  | '24x33'
  | '20x24'
  | '32x38'
  | '30x40';

export type TextStyle = (GeneralTextStyle | Sizes | TextLinear)[];

export type TextLinear = 'blueLinear' | 'yellowLinear' | 'prizeGradient' | 'prizeNumber';

export interface TextProps {
  modifiers?: TextStyle;
  type?: 'p' | 'span' | 'div';
  content?: string;
  children?: React.ReactNode;
}

const Text: React.FC<TextProps> = ({
  modifiers, type = 'p', children, content
}) => {
  const Element = type;
  return content ? (
    <Element
      className={mapModifiers('a-text', modifiers)}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  ) : (
    <Element className={mapModifiers('a-text', modifiers)}>{children}</Element>
  );
};

export default Text;
