import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  spinner: 'spinner',
  leftArrow: 'leftArrow',
  close: 'close',
};

export type IconName = keyof typeof iconList;

export type IconSize = '6' | '8' | '9' | '12' | '16' | '18' | '20' | '22' | '24' | '28' | '32' | '40' | '45' | 'full';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ iconName, size, className }) => (
  <i className={`${mapModifiers('a-icon', iconName, size)} ${className || ''}`} />
);

Icon.defaultProps = {
  size: undefined,
};

export default Icon;
