import {
  ShopsMatchByCodeDataTypes, ShopMatchSaveParamsTypes, ShopMatchDetailParamsTypes, CheckInShopCode
} from './types';

import axiosInstance from 'services/common/instance';

export const getInfoShopMatchByCodeService = async (code: string)
  : Promise<ShopsMatchByCodeDataTypes> => {
  const res = await axiosInstance.get(`shops/match/${code}`);
  return res.data?.data;
};

export const getShopMatchService = async (params: ShopMatchDetailParamsTypes)
  : Promise<ShopsMatchByCodeDataTypes> => {
  const res = await axiosInstance.get('shops/match', { params });
  return res.data?.data;
};

export const postShopMatchSaveService = async (params: ShopMatchSaveParamsTypes)
  : Promise<ShopsMatchByCodeDataTypes> => {
  const res = await axiosInstance.post('shops/match/save', params);
  return res.data?.data;
};

export const postCheckInByShopCodeService = async (params: CheckInShopCode): Promise<void> => {
  await axiosInstance.post('members/check-in-by-shop-code', params);
};

export const Placeholder = '';
