import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'tfc-components';

import Icon from 'components/atoms/Icon';

interface NavigateProps {
}

const Navigate: React.FC<NavigateProps> = () => {
  const navigate = useNavigate();
  return (
    <div className="o-navigate">
      <Container>
        <div className="o-navigate_back" onClick={() => navigate(-1)}>
          <Icon iconName="leftArrow" size="18" />
          <Typography.Text>Quay lại</Typography.Text>
        </div>
      </Container>
    </div>
  );
};

export default Navigate;
