/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Modal from 'react-modal';

import Icon from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

interface PopupProps {
  isOpenModal: boolean;
  shouldCloseOnOverlayClick?: boolean;
  title?: React.ReactNode;
  sub?: React.ReactNode;
  useBackgroundImage?: boolean;
  modifiers?: string;
  hasCloseButton?: boolean;
  handleClose?: () => void;
  children?: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({
  children,
  title,
  sub,
  isOpenModal,
  modifiers,
  hasCloseButton,
  handleClose,
  useBackgroundImage,
  shouldCloseOnOverlayClick,
}) => {
  Modal.setAppElement('#root');
  return (
    <Modal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={isOpenModal}
      onRequestClose={handleClose}
      contentLabel="Example Modal"
      className={mapModifiers(
        'o-popup',
        modifiers,
        useBackgroundImage && 'usebackgroundimage',
      )}
    >
      <div className="o-popup_body">
        {hasCloseButton && (
          <div aria-hidden className="o-popup_close" onClick={handleClose}>
            <Icon iconName="close" size="28" />
          </div>
        )}
        <div className="o-popup_wrapcontent">
          {
            title
            && (
              <div className="o-popup_title">
                <span>{title}</span>
              </div>
            )
          }
          {sub && <div className="o-popup_sub">{sub}</div>}
          <div className="o-popup_content">{children}</div>
        </div>
      </div>
    </Modal>
  );
};

Popup.defaultProps = {
  shouldCloseOnOverlayClick: undefined,
  title: '',
  sub: '',
  useBackgroundImage: undefined,
  modifiers: undefined,
  hasCloseButton: undefined,
  handleClose: undefined,

};

export default Popup;
