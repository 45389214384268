import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Container from 'components/organisms/Container';
import Popup from 'components/organisms/Popup';
import { getInfoShopMatchByCodeService } from 'services/shops';
import { ROUTES } from 'utils/constants';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputCode, setInputCode] = useState('');

  const { mutate: getInfoShopMatchByCodeMuatate, isLoading } = useMutation(
    'getInfoShopMatchByCodeService',
    (code: string) => getInfoShopMatchByCodeService(code),
    {
      onSuccess(data) {
        const params = {
          name_customer: data.name || '',
          address_customer: data.address || '',
          code_customer: data.code || '',
        };
        navigate(`${ROUTES.INFORMATION_CUSTOMER}?${new URLSearchParams(params).toString()}`);
      },
      onError() {
        toast.error('Thông tin không hợp lệ! Vui lòng thử lại');
      },
    }
  );

  return (
    <div className="p-home">
      <Container className="p-home_container">
        <Button
          className="p-home_button"
          variant={['h50']}
          handleClick={() => {
            navigate(ROUTES.SCAN);
          }}
        >
          Scan QR
        </Button>
        <Button
          className="p-home_button"
          variant={['h50']}
          handleClick={() => navigate(ROUTES.MANUAL_INPUT)}
        >
          Nhập thông tin
        </Button>
      </Container>

      <Popup
        modifiers="confirmNumber"
        isOpenModal={isModalOpen}
        title="MÃ KHÁCH HÀNG"
        handleClose={() => setIsModalOpen(false)}
        hasCloseButton
      >
        <Input
          id="code"
          name="code"
          className="p-home_input"
          value={inputCode}
          onChange={(e) => setInputCode(e.target.value)}
        />

        <Button loading={isLoading} handleClick={() => getInfoShopMatchByCodeMuatate(inputCode)}>
          XÁC NHẬN
        </Button>
      </Popup>
    </div>
  );
};

export default Home;
