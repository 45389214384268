import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/atoms/Button';
import Container from 'components/organisms/Container';
import { ROUTES } from 'utils/constants';

const CheckinResult: React.FC = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(6);

  const handleReturnHome = () => {
    navigate(ROUTES.HOME);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      handleReturnHome();
    }, 6000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, []);

  return (
    <div className="p-checkinResult">
      <Container className="p-checkinResult_container">
        <h2>CHECKIN THÀNH CÔNG</h2>
        <Button variant={['h50']} handleClick={handleReturnHome}>
          Về trang chủ
        </Button>
        <p>
          {`Tự động chuyển về trang chủ sau ${countdown} giây`}
        </p>
      </Container>
    </div>
  );
};

export default CheckinResult;
