/* eslint-disable */
import Button from 'components/atoms/Button';
import Loading from 'components/atoms/Loading';
import Navigate from 'components/organisms/Navigate';
import jsQR from 'jsqr';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getInfoShopMatchByCodeService } from 'services/shops';
import { ROUTES } from 'utils/constants';

import mapModifiers from 'utils/functions';

const Scan: React.FC = () => {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const hasCodeRef = useRef<boolean>(false);
  const animationFrameRef = useRef<number | null>(null);

  const [scanning, setScanning] = useState(false);
  const [error, setError] = useState(false);

  const { mutate: getInfoShopMatchByCodeMuatate, isLoading } = useMutation(
    'getInfoShopMatchByCodeService',
    (code: string) => getInfoShopMatchByCodeService(code),
    {
      onSuccess(data) {
        const params = {
          name_customer: data.name || '',
          address_customer: data.address || '',
          code_customer: data.code || '',
        };
        setError(false);
        navigate(`${ROUTES.INFORMATION_CUSTOMER}?${new URLSearchParams(params).toString()}`);
      },
      onError() {
        setError(true);
        toast.error('Vui lòng quét lại QRcode hoặc bấm nút “NHẬP THÔNG TIN”');
      },
    }
  )

  useEffect(() => {
    let videoStream: MediaStream | null = null;

    const startCamera = async () => {
      try {
        videoStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' },
        });

        if (videoRef.current) {
          videoRef.current.srcObject = videoStream;

          videoRef.current.onloadedmetadata = () => {
            if (videoRef.current) {
              videoRef.current.play();
              setScanning(true);
            }
          };
        }
      } catch (err) {
        console.error('Error accessing camera:', err);
      }
    };

    startCamera();

    return () => {
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    const scanQRCode = () => {
      if (!scanning || !canvasRef.current || !videoRef.current) return;

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const video = videoRef.current;

      if (video.videoWidth > 0 && video.videoHeight > 0) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Draw video frame onto the canvas
        context?.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Extract image data and decode QR
        const imageData = context?.getImageData(0, 0, canvas.width, canvas.height);

        if (imageData) {
          const code = jsQR(imageData.data, canvas.width, canvas.height);

          if (code && code.data) {
            videoRef.current.pause();
            hasCodeRef.current = true;
            getInfoShopMatchByCodeMuatate(code.data);
            if (animationFrameRef.current) {
              setScanning(false);
              cancelAnimationFrame(animationFrameRef.current);
            }
          }
        }
      }
      if (!hasCodeRef.current) {
        animationFrameRef.current = requestAnimationFrame(scanQRCode); // Repeat scanning
      }
    };

    if (scanning) {
      hasCodeRef.current = false;
      if (videoRef.current) {
        videoRef.current.play();
      }
      requestAnimationFrame(scanQRCode);
    }

    // return () => {
    //   console.log('Unmount');
    //   setScanning(false);
    // };
  }, [scanning]);

  const onHandleRetry = () => {
    setError(false);
    setScanning(true);
  };

  return (
    <div className="p-scan">
      <Navigate />
      {
        isLoading && (
          <Loading isShow variant="fullScreen" />
        )
      }
      <div className="p-scan_container">
        <video muted playsInline ref={videoRef} style={{ width: '100%', height: '100vh' }} />
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        <div className="p-scan_content">
          <div className="p-scan_corner">
            <div className={mapModifiers('p-scan_corner', '1')} />
            <div className={mapModifiers('p-scan_corner', '2')} />
            <div className={mapModifiers('p-scan_corner', '3')} />
            <div className={mapModifiers('p-scan_corner', '4')} />
          </div>
          <div className="p-scan_btnRetry">
            {
              error && (
                <Button variant={['h50']} handleClick={onHandleRetry}>Thử lại</Button>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scan;
